import React, { Component } from "react";
import withRouter from "../common/withRouter";
import AppContainer from "../common/AppContainer";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";
import Grid from "../common/GridWrapper";
import Button from "../common/ButtonWrapper";
import WarningDialog from "../common/WarningDialog";
import HelpDialog from "../common/HelpDialog";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import FileUploadIcon from "@mui/icons-material/Publish";
import ContentCopyIcon from "@mui/icons-material/FileCopyOutlined";
import saveAs from "file-saver";
import { connect } from "react-redux";
import * as rotationActions from "./actions";

import * as utils from "./CropManagementUtils";
import ExportSystem from "./ExportRotationSystem";
import CopyProjectTemplateDialog from "./CopyProjectTemplateDialog";
import ImportTemplateDialog from "./ImportTemplateDialog";
import { RotationSystem, RotationEvent, ProjectRotationSystem } from "./models";
import TemplateLibraryTable from "./tables/TemplateLibraryTable";
import Tutorials from "./Tutorials";

const styles = {
    paddingLeft: {
        paddingLeft: "24px !important",
    },
    optionButton: {
        border: "1px solid #979797",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        margin: 12,
        minHeight: 36,
        color: "#979797",
        backgroundColor: "#fff",
        boxShadow: "none",
        "&:hover": {
            border: "1px solid #fff",
            backgroundColor: "#ff7d32",
            color: "#fff",
        },
    },
    optionButtonIcon: {
        marginRight: 10,
        fontSize: 30,
        border: "1px solid",
        padding: 4,
        strokeWidth: 2,
        borderRadius: 4,
        margin: 10,
        borderColor: "inherit",
    },
};

const allRotationSystems = RotationSystem.selectAll((rotationSystem) => {
    const cropYears = rotationSystem.cropyears.all().toModelArray();
    return {
        has_cropyear: cropYears.length > 0,
        status_label: rotationSystem.is_valid ? "OK" : "Invalid",
        ...rotationSystem.ref,
        events: rotationSystem.events.toModelArray().map((e) => e._fields),
    };
});

const allProjectRotationSystems = ProjectRotationSystem.selectAll((system) => ({ ...system.ref }));

class RotationLibrary extends Component {
    state = {
        rotationEvents: [],
        start: false,
        rotationSystemToPreview: null,
        copyProjectTemplate: false,
        importTemplate: false,
        copySystemId: null,
        exportSystemId: null,
        deleteSystemId: null,
        operations: [],
        cropsOptions: [],
    };

    createBlankRotationSystem = () => {
        var systemId = utils.createBlankRotationSystem(this.props.ormRotationSystemCreate, null);
        if (this.props.match.params.cropyearId)
            this.props.history.push("/rotation/" + systemId + "/cropyear/" + this.props.match.params.cropyearId);
        else this.props.history.push("/rotation/" + systemId);
    };

    handleCopySystemClick = (systemId) => {
        this.setState({
            copySystemId: systemId,
        });
    };

    confirmCopy = () => {
        this.copySystem(this.state.copySystemId);
    };

    cancelCopy = () => {
        this.setState({ copySystemId: null });
    };

    handleExportTemplateClick = (systemId) => {
        this.setState({
            exportSystemId: systemId,
        });
    };

    handleImportTemplateClick = () => {
        this.setState({
            importTemplate: true,
        });
    };

    handleCopyProjectTemplateDialogCloseClick = () => {
        this.setState({
            copyProjectTemplate: false,
        });
    };

    handleReturnToCropyear = () => {
        this.props.history.push("/cropyear/" + this.props.match.params.cropyearId);
    };

    handleImportTemplateCancelClick = () => {
        this.setState({
            importTemplate: false,
        });
    };

    handleViewSystemClick = (systemId) => {
        if (this.props.match.params.cropyearId)
            this.props.history.push("/rotation/" + systemId + "/cropyear/" + this.props.match.params.cropyearId);
        else this.props.history.push("/rotation/" + systemId);
    };

    copySystem = (systemId) => {
        const rotationSystem = this.props.rotationSystems.filter((s) => s.id === systemId)[0];

        let rotationSystemObj = {
            name: "Copy of " + rotationSystem.name,
            template: rotationSystem.id,
            template_name: rotationSystem.template_name,
        };

        var events = rotationSystem.events.map((event) => {
            return {
                date: event.date,
                operation: event.operation,
                crop: event.crop,
                residue: event.residue,
            };
        });

        const parameters = {
            ormRotationSystemCreate: this.props.ormRotationSystemCreate,
            ormRotationEventCreate: this.props.ormRotationEventCreate,
            ormRotationSystemUpdate: this.props.ormRotationSystemUpdate,
            rotationSystem: rotationSystemObj,
            events: events,
            projectIds: null,
            operations: this.state.operations,
            crops: this.state.crops,
        };

        const newSystemId = utils.createRotationSystemAndEvents(parameters);

        this.setState({
            copySystemId: null,
        });

        if (newSystemId) {
            if (this.props.match.params.cropyearId)
                this.props.history.push("/rotation/" + newSystemId + "/" + this.props.match.params.cropyearId);
            else this.props.history.push("/rotation/" + newSystemId);
        }
    };

    exportSystem = (systemId, events) => {
        this.setState({
            exportSystemId: null,
        });

        let managementSystem = this.props.rotationSystems.filter((system) => system.id === systemId)[0];

        //  remove unwanted info in the events
        events = events.map((evt) => {
            // Make a copy first or the Redux state will be broken! (#576)
            let e = { ...evt };
            ["modify_user", "modify_date", "id", "system"].forEach((key) => {
                delete e[key];
            });
            return e;
        });

        var rotation = { managements: [{ name: managementSystem.name, events: events }] };
        var text = JSON.stringify(rotation);
        var filename = managementSystem.name + ".rot";
        var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        saveAs(blob, filename);
    };

    handleDeleteSystemClick = (systemId) => {
        this.setState({ deleteSystemId: systemId });
    };

    okDeleteSystem = () => {
        this.props.ormRotationSystemDelete(this.state.deleteSystemId);
        this.setState({ deleteSystemId: null });
    };

    cancelDeleteSystem = () => {
        this.setState({ deleteSystemId: null });
    };

    handleSearchNrcsLmodClick = () => {
        if (this.props.match.params.cropyearId)
            this.props.history.push("/rotation/search/cropyear/" + this.props.match.params.cropyearId);
        else this.props.history.push("/rotation/search/");
    };

    handleCopyProjectTemplateClick = () => {
        this.setState({
            copyProjectTemplate: true,
        });
    };

    handleBuildYourOwnClick = () => {
        this.props.handleBuildYourOwnClick();
    };

    getProjectOptions = () => {
        var project_ids = [];
        var project_options = [];
        this.props.projectRotationSystems.forEach((s) => {
            if (project_ids.indexOf(s.project_id) === -1) {
                project_ids.push(s.project_id);
                project_options.push({
                    id: s.project_id.toString(),
                    name: s.project_name,
                });
            }
        });
        project_options.unshift({ id: "0", name: "None" });
        return project_options;
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadCropAndOperationData();
    }

    loadCropAndOperationData() {
        let that = this;
        Promise.all([utils.CropsRequest, utils.OperationsRequest]).then(function (values) {
            let operations = values[1]["result"][0]["value"]["operations"],
                crops = values[0];

            crops.forEach((crop) => {
                crop["show"] = true;
            });

            that.setState({
                operations: operations,
                crops: crops,
            });
        });
    }

    render() {
        const { classes, rotationSystems, projectRotationSystems } = this.props;

        const { deleteSystemId, exportSystemId, copySystemId, copyProjectTemplate, importTemplate } = this.state;

        const columnData = [
            { id: "name", numeric: false, disablePadding: false, label: "Name", allowSort: true },
            {
                id: "template_name",
                numeric: false,
                disablePadding: false,
                label: "NRCS Template Name",
                allowSort: true,
            },
            {
                id: "modify_date",
                numeric: false,
                disablePadding: false,
                label: "Last Updated",
                allowSort: true,
                isDate: true,
            },
            { id: "status_label", numeric: false, label: "Status", allowSort: true },
        ];

        const filtered_rotation_systems = rotationSystems.filter((s) => !s.project);

        const hasProjectRotationSystems = projectRotationSystems.length > 0;

        const projectOptions = this.getProjectOptions();

        const helpDialogText = copySystemId
            ? "Are you sure you want to copy rotation system: " +
              rotationSystems.filter((s) => s.id === copySystemId)[0].name +
              " - " +
              rotationSystems.filter((s) => s.id === copySystemId)[0].template_name
            : "";

        return (
            <AppContainer authenticated color="crop" title="Crop Rotation Library">
                {exportSystemId && <ExportSystem rotationSystemId={exportSystemId} exportSystem={this.exportSystem} />}

                <HelpDialog
                    open={!!copySystemId}
                    onClose={() => this.setState({ copySystemId: null })}
                    question="Copy Crop Rotation Template"
                    text={helpDialogText}
                    confirmAction={this.confirmCopy}
                    cancelAction={this.cancelCopy}
                />

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.okDeleteSystem}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteSystem}
                    open={!!deleteSystemId}
                    title="Delete Rotation System"
                    text={"Are you sure you want to delete this rotation system?"}
                />

                <CopyProjectTemplateDialog
                    open={copyProjectTemplate}
                    projectOptions={projectOptions}
                    rotationSystems={projectRotationSystems}
                    onDialogCloseClick={this.handleCopyProjectTemplateDialogCloseClick}
                    cropyearId={this.props.match.params.cropyearId}
                    operations={this.state.operations}
                    crops={this.state.crops}
                    ormRotationSystemCreate={this.props.ormRotationSystemCreate}
                    ormRotationEventCreate={this.props.ormRotationEventCreate}
                    ormRotationSystemUpdate={this.props.ormRotationSystemUpdate}
                    ormProjectRotationSystemLoadDetail={this.props.ormProjectRotationSystemLoadDetail}
                />

                <ImportTemplateDialog
                    open={importTemplate}
                    projectOptions={projectOptions}
                    cropyearId={this.props.match.params.cropyearId}
                    onReturnToCropyear={this.handleReturnToCropyear}
                    onImportTemplateCancelClick={this.handleImportTemplateCancelClick}
                    ormRotationSystemCreate={this.props.ormRotationSystemCreate}
                    ormRotationEventCreate={this.props.ormRotationEventCreate}
                    ormRotationSystemUpdate={this.props.ormRotationSystemUpdate}
                />

                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            A complete Crop Rotation System improves the accuracy of the Soil Carbon and Soil
                            Conservation metric results derived by the USDA Natural Resources Conservation Service
                            (NRCS) water and wind erosion models and Soil Conditioning Index (SCI). Crop Rotation
                            Systems are customized to describe your management practices (e.g. field operations, crops)
                            for a single or multi-year crop rotation. These systems are representative of your planned
                            field operations. Accuracy should be managed to the appropriate monthly timing (early, mid,
                            end of month), nutrient and manure application, and ground disturbing activities. The
                            Fieldprint Platform Crop Rotation Builder provides for two key options: create a new
                            template or modify an existing template. Previously saved templates and templates found in
                            the NRCS Conservation Resources Land Management and Operations Database (LMOD) are a great
                            starting point to document your crop rotation and are fully customizable.
                        </Typography>
                        <Typography variant="display4">Add New Template</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Tutorials />
                    </Grid>

                    <Grid item xs={12} style={{ paddingLeft: 0 }}>
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                md={hasProjectRotationSystems ? 3 : 4}
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                                <Button
                                    fullWidth={true}
                                    className={classes.optionButton}
                                    onClick={this.createBlankRotationSystem}
                                    color="primary"
                                >
                                    <EditIcon className={classes.optionButtonIcon} />
                                    Create Custom Template
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                md={hasProjectRotationSystems ? 3 : 4}
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                                <Button
                                    fullWidth={true}
                                    className={classes.optionButton}
                                    onClick={this.handleSearchNrcsLmodClick}
                                >
                                    <SearchIcon className={classes.optionButtonIcon} />
                                    Search NRCS LMOD
                                </Button>
                            </Grid>

                            {hasProjectRotationSystems && (
                                <Grid
                                    item
                                    xs={6}
                                    md={hasProjectRotationSystems ? 3 : 4}
                                    style={{ paddingLeft: 10, paddingRight: 10 }}
                                >
                                    <Button
                                        fullWidth={true}
                                        className={classes.optionButton}
                                        onClick={this.handleCopyProjectTemplateClick}
                                    >
                                        <ContentCopyIcon className={classes.optionButtonIcon} />
                                        Copy Project Template
                                    </Button>
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={6}
                                md={hasProjectRotationSystems ? 3 : 4}
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                                <Button
                                    fullWidth={true}
                                    className={classes.optionButton}
                                    onClick={this.handleImportTemplateClick}
                                >
                                    <FileUploadIcon className={classes.optionButtonIcon} />
                                    Import Template
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="display4" gutterBottom>
                            Your Template Library
                        </Typography>

                        <TemplateLibraryTable
                            orderBy="modify_date"
                            order="desc"
                            data={filtered_rotation_systems}
                            columnData={columnData}
                            noRecordsMessage={"No saved templates."}
                            onView={this.handleViewSystemClick}
                            onCopy={this.handleCopySystemClick}
                            onDownload={this.handleExportTemplateClick}
                            onSave={null}
                            onDelete={this.handleDeleteSystemClick}
                        />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

RotationLibrary = connect(
    (state) => ({
        mgmtState: (state.rotation && state.rotation.mgmt) || {},
        rotationSystems: allRotationSystems(state),
        projectRotationSystems: allProjectRotationSystems(state),
    }),
    {
        ...rotationActions,
        ...RotationSystem.actions,
        ...ProjectRotationSystem.actions,
        ...RotationEvent.actions,
    },
)(RotationLibrary);

export default withStyles(styles)(withRouter(RotationLibrary));
